@use 'sass:math';

/*Returns a CSS clamp function which resolves to $size-at-min-width when page width is
below $min-width,
$size-at-max-width when page width is above $max-width,
and linearly scales between the two between $min-width and $max-width*/

@function clamp-calc($min-width, $max-width, $size-at-min-width, $size-at-max-width) {
  $slope: math.div(($size-at-max-width - $size-at-min-width), ($max-width - $min-width));
  $y-axis-intersection: -1 * $min-width * $slope + $size-at-min-width;
  $return-value: clamp(#{$size-at-min-width}, #{-1 * $min-width * $slope + $size-at-min-width} + #{math.div(($size-at-max-width - $size-at-min-width), ($max-width - $min-width))} * 100vw, #{$size-at-max-width});
  @return $return-value;
}
@function clamp-calc-v($min-height, $max-height, $size-at-min-height, $size-at-max-height) {
  $slope: math.div(($size-at-max-height - $size-at-min-height), ($min-height - $max-height));
  $y-axis-intersection: -1 * $max-height * $slope + $size-at-min-height;
  $return-value: clamp(#{$size-at-min-height}, #{-1 * $max-height * $slope + $size-at-min-height} + #{math.div(($size-at-max-height - $size-at-min-height), ($min-height - $max-height))} * 100vh, #{$size-at-max-height});
  @return $return-value;
}


/*
Mobile first media query mixin
source - https://jonsuh.com/blog/managing-responsive-breakpoints-with-sass-maps/
*/
@mixin mq($mq-breakpoint, $mq-breakpoints: $screens) {
    /*
    If $mq-breakpoint is a key that exists in
    $mq-breakpoints, get and use the value
    */
    @if map-has-key($mq-breakpoints, $mq-breakpoint) {
      $mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);
    }
    @if $mq-breakpoints == $screensV{
      @media screen and (max-height: #{$mq-breakpoint}) {
        @content;
      }
    } @else {
      @media screen and (min-width: #{$mq-breakpoint}) {
        @content;
      }
    }
}


@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
@function pxToRem($size) {
  @return (strip-unit($size) / 16) * 1rem;
}
