label, input, select{
  width: 100%;
}
label{
  @apply text-mid uppercase text-sm;
}
fieldset{
  display: flex;
  flex-direction: column-reverse;
}
input[type="password"]{
  appearance: none;
}
input,
input[type="text"],
input[type="email"],
input[type="tel"]
input[type="password"],
textarea,
select,
input[type="radio"],
input[type="checkbox"]{
  @apply border border-mid bg-dark rounded-none;
}
input,
input[type="text"],
input[type="email"],
input[type="tel"]
input[type="password"],
textarea,
select {
  @apply px-3 py-2 text-mid;
  transition: all 0.3s ease-in-out;
  + label{
    transition: all 0.3s ease-in-out;
  }
  &:focus{
    outline: none;
    @apply border-hotpink;
      + label{
        @apply text-hotpink;
      }
  }
}
input[type="radio"],
input[type="checkbox"]{
  width: 20px;
  height: 20px;
  appearance: none;
  cursor: pointer;
  + label{
    cursor: pointer;
  }
}
input[type="checkbox"]:checked{
  background: center no-repeat url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAM1BMVEWAgID///+IiIj4+Pjw8PDg4ODAwMCgoKCQkJCwsLCoqKjY2NjQ0NCYmJjo6OjIyMi4uLglI9MXAAAAqklEQVQ4y+WTOxKDMAwF/ZX/Nvc/bRikcVIQPxepwrbsGFjJ6jHktOcZq3vYEUmflAi9qBloevYK8gJ7Fh7oWCTkHew5g9NcwJKDPY+8ppmwmabCIUsa86s0JGnUZpp8//Q9qrpMUyyZOeSL9nUFXP5MM9Qtnd92npKWaZIWapQ0x+I/mWUaM6aBhhy9GHj/gxMJXyiaX0podnWmQTQv+4/JTne1haGg/p0XQ6kDYilQt/kAAAAASUVORK5CYII=')
  ;
  background-size: cover;
}

