body{
  @apply bg-dark;
}
#root{
  @apply bg-dark text-white;
  min-height: 100vh;
  min-height: 100svh;
  overflow-x: hidden
}
.main-content{
  min-height: 100vh;
  min-height: 100svh;
}
