@tailwind base;
@tailwind components;
@tailwind utilities;

@import './config/var.scss';
@import './config/utility.scss';
@import './base/base.scss';
@import './base/container.scss';
@import './base/section.scss';
@import './base/form.scss';
@import './components/button.scss';
@import './components/animation.scss';
@import './components/loader.scss';
@import './components/typography.scss';
@import './vendor/swiper.scss';

.player-color-1{
  @apply text-amethyst;
}
.player-color-2{
  @apply text-flamingo;
}
.player-color-3{
  @apply text-carnation;
}
.player-status-list{
  .player-status{
    transition: all 0.5s ease-in-out;
    border-color: currentColor;
    svg{
      @apply text-dark;
    }
    &.not-joined{
      opacity: 0.7
    }
    &.ready{
      background: currentColor;
      &.player-color-1{
        @apply drop-shadow-amethyst-sm;
      }
      &.player-color-2{
        @apply drop-shadow-flamingo-sm;
      }
      &.player-color-3{
        @apply drop-shadow-carnation-sm;
      }
    }
    &.not-ready{
      &.player-color-1{
          animation: 0.4s $easeInOutQuart flash-bg-amethyst;
      }
      &.player-color-2{
          animation: 0.4s $easeInOutQuart flash-bg-flamingo;
      }
      &.player-color-3{
          animation: 0.4s $easeInOutQuart flash-bg-carnation;
      }
    }
  }
}
.bg-double-shadow{
  position: relative;
  &.rounded-lg{
    &:before, &:after{
      border-radius: 0.5rem;
    }
  }
  &.rounded-full{
    &:before, &:after{
      border-radius: 9999px;
    }
  }
  &:before, &:after{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
  &:before{
    @apply bg-hotpink;
    left: $chromaticOffsetX;
    top: $chromaticOffsetY;
    transform: rotate($chromaticRotate);
    transform-origin: 100% 100%;
  }
  &:after{
    @apply bg-sky;
    right: $chromaticOffsetX;
    bottom: $chromaticOffsetY;
    transform: rotate($chromaticRotate);
    transform-origin: 0% 0%;
  }
}
.text-double-shadow{
  position: relative;
  display: grid;
  > *{
    grid-column: 1;
    grid-row: 1;
    position: relative;
    z-index: 1;
  }
  .text-white{
    z-index: 3;
  }
  .text-hotpink{
    left: $chromaticOffsetX;
    top: $chromaticOffsetY;
    transform: rotate($chromaticRotate);
    transform-origin: 100% 100%;
  }
  .text-sky{
    right: $chromaticOffsetX;
    bottom: $chromaticOffsetY;
    transform: rotate($chromaticRotate);
    transform-origin: 0% 0%;
  }
}
.clone-parent{
  position: relative;
  :first-child{
    position: relative;
    z-index: 2;
  }
  :last-child{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

.clip-bottom-right{
  clip-path: polygon(100% 0, 100% calc(100% - 15px), calc(100% - 9px) 100%, 0 100%, 0 0);
}
.clip-top-right{
  clip-path: polygon(calc(100% - 9px) 0, 100% calc(100% - 15px), 100% 100%, 0 100%, 0 0);
}

@supports not (aspect-ratio: 66/82){
  .aspect-\[66\/82\]{
    padding-bottom: 124.24%;
    position: relative;
    > * {
      position: absolute !important;
      width: 100%;
      height: 100%;
    }
  }
}

.section-player-list{
  .player-list-item{
    @include mq('xl', $screensV) {
      max-width: 1180px;
      width: 130vh;
      aspect-ratio: 1412 / 254;
      height: auto;
    }
  }
}
